import mainPic from '../../../assets/images/main-pic.png';
import cls from './TopBLock.module.scss';
import pic2 from "../../../assets/images/gradient-mission.png";

const TopBLock = () => {
  return (
    <div className={cls.parallax}>
      <div className={cls.wrapper}>
        <div className={cls.container}>
          <div className={cls.triangle3}></div>
          <div className={cls.left}>
            <div className={cls.triangle1}></div>
            <div className={cls.triangle2}></div>
            <h1>Управляйте своим денежным потоком на лету!</h1>
            <p>Единая платформа, на которой вы можете управлять входящими и исходящими платежами, расчетами, входами и выходами.</p>
          </div>
          <div className={cls.right}>
            <img src={mainPic} className={cls.pic} alt="main-pic"/>
            <img className={cls.gradient} src={pic2} alt="gradient"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBLock;
