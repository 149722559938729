import HomePage from "./pages/Home/HomePage";
import Footer from "./components/Footer/Footer";
import NavBar from "./components/NavBar/NavBar";
import './App.css';

function App() {
  return (
    <div className="App">
      <NavBar/>
      <div></div>
      <main className="main-content">
        <HomePage/>
      </main>
      <Footer/>
    </div>
  );
}

export default App;
