import React, {useState} from 'react';
import FormInput from "../../UI/FormInput/FormInput";
import TextArea from "../../UI/FormInput/TextArea";
import Button from "../../UI/Button/Button";
import cls from './ContactUs.module.scss'
import shape from "../../../assets/images/greyTriangle.svg";

const ContactUs = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorField, setErrorField] = useState('');
  const [success, setSuccess] = useState(false);

  const onSubmitForm = async (e) => {
    e.preventDefault();

    setSuccess(false)
    setErrorField('')

    if (!email || !phone || !message) {
      setErrorField('Заполните все поля формы')
      return;
    }

    setIsLoading(true)

    const object = {
      email,
      phone,
      message
    }

    try {
      let response = await fetch(
        "https://api.test.paylink.vip/api/mails",
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(object)
        });

      console.log("response", response);

      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }

      let result = await response.json();

      console.log('result', result)

      if (result.ok === true) {
        setSuccess(true)
      } else {
        setErrorField('Произошла ошибка. Попробуйте позже.')
      }

    } catch (error) {
      console.error(error);
      setErrorField('Произошла ошибка. Попробуйте позже.')
    }
    setIsLoading(false)
  }

  return (
    <div className={cls.ContactUs} id="contacts">
      <div className={cls.wrapper}>
        <div className={cls.container}>
          <div className={cls.right}>
            <form
              onSubmit={onSubmitForm}
              className={cls.form}
            >
              <FormInput
                placeholder='Ваш Email'
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormInput
                placeholder='Телефон'
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <TextArea
                placeholder='Напишите свое сообщение'
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              {
                errorField &&
                <div className={cls.errorField}>{errorField}</div>
              }
              {
                success &&
                <div className={cls.success}><b>Мы получили ваше сообщение.</b> <br/>
                  Наши менеджеры свяжутся с вами в ближайшее время.</div>
              }
              <Button isLoading={isLoading} disabled={isLoading}>Связаться с нами</Button>
            </form>
          </div>
          <img className={cls.greyTriangle} src={shape} alt="shape"/>
          <div className={cls.left}>
            <div className={cls.triangle1}></div>
            <div className={cls.triangle2}></div>

            <h2>У вас есть вопросы?</h2>
            <p>У вас есть вопросы или предложения по сотрудничеству? Не стесняйтесь поделиться ими! Напишите нам и мы ответим вам в самое короткое время!</p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ContactUs;
