import cls from "./Loader.module.scss";

const Loader = (props) => {
  const { orange } = props;

  const style = `${cls.ldsRing} ${orange ? cls.orange : ""}`;

  return (
    <div className={style}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loader;
