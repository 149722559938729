import cls from "./FormInput.module.scss"

const FormInput = (props) => {
  const { type = "text", name, value, onChange, placeholder, isError, ...otherProps } = props;

  const inputStyle = `${cls.FormInput} ${isError ? cls.error : ''}`

  return (
    <input
      autoComplete="new-password"
      className={inputStyle}
      id={name}
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      {...otherProps}
    />
  );
};

export default FormInput;
