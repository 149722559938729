import React from 'react';
import cls from "./OurMission.module.scss";
import pic1 from '../../../assets/images/pic-mission1.png'
import pic2 from '../../../assets/images/gradient-mission.png'
import shape from '../../../assets/images/greyTriangle.svg'

const OurMission = () => {
  return (
    <div className={cls.OurMission} id="our-mission">
      <div className={cls.wrapper}>
        <div className={cls.container}>
          <div className={cls.block}>
            <div className={cls.left}>
              <h2>Наша миссия</h2>
              <p>Мы организуем мгновенный прием платежей, чтобы освободить время наших клиентов для их свободного развития и роста
              </p>
            </div>
            <div className={cls.right}>
              <img className={cls.gradient} src={pic2} alt="gradient"/>
              <img className={cls.pic} src={pic1} alt="mission"/>
              <img className={cls.greyTriangle1} src={shape} alt="shape"/>
              <img className={cls.greyTriangle2} src={shape} alt="shape"/>
              <div className={cls.triangle1}></div>
              <div className={cls.triangle2}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
