import cls from "./Services.module.scss";
import iconCheck from '../../../assets/images/icon-check.svg'
import shape from "../../../assets/images/greyTriangle.svg";

const P2p = () => {
  return (
    <div className={cls.P2P} id="P2P">
      <div className={cls.wrapper}>
        <div className={cls.container}>
          <img className={cls.triangleP2P} src={shape} alt="grey-triangle"/>
          <img className={cls.triangleP2P2} src={shape} alt="grey-triangle"/>
          <div className={cls.top}>
            <div className={cls.title}>
              <h2>ПЕРВИЧКА P2P</h2>
              <p>= перевод по реквизитам карты, перевод по номерам телефонов</p>
              <p> P2P - USDT</p>
            </div>
            <div className={cls.topList}>
              <div className={cls.topItem}>
                <div className={cls.triangleIcon}></div>
                <div className={cls.topItemText}>
                  <h3>Тип трафика</h3>
                  <p>Первичка РФ, Вторичка РФ</p>
                </div>
              </div>
              <div className={cls.topItem}>
                <div className={cls.triangleIcon}></div>
                <div className={cls.topItemText}>
                  <h3>Авторизация</h3>
                  <p>RUB</p>
                </div>
              </div>
              <div className={cls.topItem}>
                <div className={cls.triangleIcon}></div>
                <div className={cls.topItemText}>
                  <h3>Тип карт</h3>
                  <p>VISA/MС/МИР</p>
                </div>
              </div>
              <div className={cls.topItem}>
                <div className={cls.triangleIcon}></div>
                <div className={cls.topItemText}>
                  <h3>Конверсия</h3>
                  <p>80% +</p>
                </div>
              </div>
              {/*<div className={cls.topItem}>
                <div className={cls.triangleIcon}></div>
                <div className={cls.topItemText}>
                  <h3>Квота</h3>
                  <p>100м/сутки</p>
                </div>
              </div>
              <div className={cls.topItem}>
                <div className={cls.triangleIcon}></div>
                <div className={cls.topItemText}>
                  <h3>Фильтрация</h3>
                  <p>Без Black LIst</p>
                </div>
              </div>*/}
            </div>
          </div>
          <div className={cls.tech}>
            <h2>Параметры продукта</h2>
            <div className={cls.techList}>
              <div className={cls.techCol}>
                <div className={cls.techItem}>
                  <img src={iconCheck} alt="check"/>
                  <div className={cls.techItemText}>
                    <h3>USDT</h3>
                    <p>T+0 (семь дней в неделю)</p>
                  </div>
                </div>
                <div className={cls.techItem}>
                  <img src={iconCheck} alt="check"/>
                  <div className={cls.techItemText}>
                    <h3>MIN:</h3>
                    <p>500 руб</p>
                  </div>
                </div>
              </div>
              <div className={cls.techCol}>
                <div className={cls.techItem}>
                  <img src={iconCheck} alt="check"/>
                  <div className={cls.techItemText}>
                    <h3>MAX.:</h3>
                    <p>600 000 руб</p>
                  </div>
                </div>
                <div className={cls.techItem}>
                  <img src={iconCheck} alt="check"/>
                  <div className={cls.techItemText}>
                    <h3>Успешных транзакций с одной карты в сутки:</h3>
                    <p>50 шт</p>
                  </div>
                </div>
              </div>
              <div className={cls.techCol}>
                <div className={cls.techItem}>
                  <img src={iconCheck} alt="check"/>
                  <div className={cls.techItemText}>
                    <h3>Успешных транзакций на одну карту в сутки:</h3>
                    <p>50 шт</p>
                  </div>
                </div>
              </div>
            </div>
           {/* <div className={cls.redText}>
              Без верхних комиссий. Без роллинг-резерва. Без штрафов за чарджи.
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default P2p;
