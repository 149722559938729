import cls from "./FormInput.module.scss";

const TextArea = (props) => {
  const {name, value, onChange, placeholder, isError, ...otherProps} = props;

  const inputStyle = `${cls.FormInput} ${isError ? cls.error : ''}`

  return (
    <textarea
      autoComplete="new-password"
      className={inputStyle}
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      maxLength={200}
      rows={3}
      {...otherProps}
    />
  );
};

export default TextArea;
