import {useState} from "react";
import logo from '../../assets/images/cyberpay-logo.svg'
import cls from "./NavBar.module.scss";

const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const onClickBurger = () => {
    setShowMenu((prevState) => !prevState);
  };

  const onCloseMenu = () => {
    setShowMenu(false);
  };

  const menuShowStyle = `${cls.navLinks} ${showMenu ? cls.navLinksShow : ""}`;
  const burgerStyle = `${cls.navBurger} ${showMenu ? cls.burgerClose : ""}`;

  return (
    <header className={cls.NavBar}>
      <div className={cls.wrapper}>
        <div className={cls.container}>
          <img src={logo} alt="logo"/>
          <nav className={menuShowStyle}>
            <ul className={cls.list}>
              <li>
                <a href="#features" className={cls.link} onClick={onCloseMenu}>
                  О нас
                </a>
              </li>
              <li>
                <a href="#our-mission" className={cls.link} onClick={onCloseMenu}>
                  Наша миссия
                </a>
              </li>
              <li>
                <a href="#P2P" className={cls.link} onClick={onCloseMenu}>
                  ПЕРВИЧКА P2P
                </a>
              </li>
              <li>
                <a href="#contacts" className={cls.link} onClick={onCloseMenu}>
                  Связаться с нами
                </a>
              </li>
            </ul>
            <div className={cls.buttons}>
              <a
                className={cls.apiLink}
                href="https://api.cyberpay.one/api.html"
                target="_blank"
                rel="noreferrer"
                onClick={onCloseMenu}
              >
                API
              </a>
              <a
                href="https://login.cyberpay.one/"
                target="_blank"
                rel="noreferrer"
                className={cls.accountLink}
                onClick={onCloseMenu}
              >
                Личный кабинет
              </a>
            </div>
          </nav>
          <button
            className={burgerStyle}
            onClick={onClickBurger}
          ></button>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
