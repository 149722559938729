import cls from "./Footer.module.scss";
import logo from '../../assets/images/cyberpay-logo2.svg'
import paySystems from '../../assets/images/pay-systems.png'
import icon1 from '../../assets/images/footerImgPdf.png'
import icon2 from '../../assets/images/footerImgLocate.png'

const Footer = () => {
  return (
    <div className={cls.Footer}>
      <div className={cls.wrapper}>
        <div className={cls.container}>
          <div className={cls.top}>
            <div className={cls.left}>
              <img src={logo} alt="logo"/>
            </div>
            <div className={cls.right}>
              <div className={`${cls.col} ${cls.block1}`}>
                <img className={cls.icon} src={icon1} alt="icon1"/>
                <div className={cls.text}>
                  <p>CYBERPAY ONE LIMITED</p>
                  <p>Incorporated under the laws of Hong Kong SAR of China Under</p>
                  <p>The Companies Ordinance (Cap. 622)</p>
                  <a className={cls.link}
                     href="/docs/cyberpay-certificate.pdf"
                     target="_blank"
                     rel="noreferrer"
                  >BusinessRegistrationNo: 76616435</a>
                </div>
              </div>
              <div className={`${cls.col} ${cls.block2}`}>
                <img className={cls.icon} src={icon2} alt="icon2"/>
                <div className={cls.text}>
                  <p>Registered address:</p>
                  <p>Unit 1603, 16th Floor, The L.
                    Plaza, 367 - 375 Queen's Road
                    Central, Sheung Wan, Hong
                    Kong</p>
                </div>
              </div>
            </div>
            <div className={cls.paySystems}>
              <img src={paySystems} alt="pay"/>
            </div>
          </div>
          <div className={cls.bottom}>
            <div>© 2023 cyberpay.one</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
