import cls from './Features.module.scss'
import pic from '../../../assets/images/pic-features.png'
import shape from '../../../assets/images/greyTriangle.svg'

const Features = () => {
  return (
    <div className={cls.Features} id="features">
      <div className={cls.wrapper}>
        <div className={cls.container}>
          <img className={cls.triangle1} src={shape} alt="grey-triangle"/>
          <img className={cls.triangle2} src={shape} alt="grey-triangle"/>
          <img className={cls.triangle3} src={shape} alt="grey-triangle"/>
          <div className={cls.left}>
            <img src={pic} alt="features"/>
          </div>
          <div className={cls.right}>
            <h2>Особенности наших инструментов</h2>
            <p>Мы предоставляем универсальное платежное решение для игровой индустрии, которое объединяет все
              необходимые методы оплаты, чтобы удовлетворить разнообразные потребности пользователей, не требуя
              интеграции с несколькими партнерами.</p>
            <a
              href="https://api.cyberpay.one/api.html"
              target="_blank"
              rel="noreferrer"
            >Познакомиться с API ➜</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
