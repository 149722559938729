import TopBLock from "../../components/landing/TopBlock/TopBLock";
import ContactUs from "../../components/landing/ContactUs/ContactUs";
import Features from "../../components/landing/Features/Features";
import OurMission from "../../components/landing/OurMission/OurMission";
import P2p from "../../components/landing/Services/P2P";

const HomePage = () => {
  return (
    <>
      <TopBLock/>
      <Features/>
      <OurMission/>
      <P2p/>
      <ContactUs/>
    </>
  );
};

export default HomePage;
